import classNames from "classnames";


const LiBook = ({book, active, onClick, hidden = false}) => (
    <li onClick={onClick} className={classNames({hovered: active})} style={{display: hidden ? "none" : ""}}>
        <span className="clickable">
            <span className="sub-title">{book.short_name}</span>
            <span className="title">{book.name}</span>
        </span>
    </li>
)

export default LiBook;