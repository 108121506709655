import { clear, get, set } from "idb-keyval";

const URL_BIBLE = "/static/bible.json";
const URL_BIBLE_CAPTION = "/static/bible_captions.json";
const URL_BIBLE_WORDS = "/static/words";
const URL_BIBLE_WORD_DATA_V3 = "/static/bible_data.v3";
const SETTING = "setting";
const USER_SETTING_VERSION = 1;

const loader = async (url) => {
  // if (false)
  try {
    const local = await get(url);

    if (local) {
      const r = JSON.parse(local);
      if (r.length > 0) {
        console.log("load from local", url);
        return r;
      }
    }
  } catch (err) {
    console.error(err);
  }

  const response = await fetch(url);
  const value = await response.json();
  setTimeout(async () => await set(url, JSON.stringify(value)), 100);
  // try {
  //   await set(url, JSON.stringify(value));
  // } catch (e) {
  //   console.error(e);
  // }

  console.log("load from server", url);
  return value;
};
/**
 *
 * @return {Promise<IBibleData[]>}
 */
export const loadBible = async () => {
  return await loader(URL_BIBLE);
};
export const loadBibleSearch = async (language) => {
  return await loader(`${URL_BIBLE_WORDS}/${language}/search.json`);
};
export const loadBibleSearchDataV3 = async (language = "korean") =>
  await loader(`${URL_BIBLE_WORD_DATA_V3}/${language}/search.json`);
export const loadBibleCaption = async () => {
  return await loader(URL_BIBLE_CAPTION);
};
export const loadBibleWords = async (language, book_id, chapter_id) => {
  return await loader(
    `${URL_BIBLE_WORDS}/${language}/bible_words.${book_id}.${chapter_id}.json`
  );
};
export const saveUserSetting = async (setting) => {
  const tempSetting = { ...(await loadUserSettings()) };
  tempSetting.version =
    setting.version ?? tempSetting.version ?? USER_SETTING_VERSION;

  if (setting.lastViewed) {
    tempSetting.lastViewed = tempSetting.lastViewed ?? {
      bookId: 1,
      chapter: 1,
    };
    tempSetting.lastViewed.bookId =
      setting.lastViewed.bookId ?? tempSetting.lastViewed.bookId;
    tempSetting.lastViewed.chapter =
      setting.lastViewed.chapter ?? tempSetting.lastViewed.chapter;
  } else tempSetting.lastViewed = { bookId: 1, chapter: 1 };

  tempSetting.shouldRedirectToLastViewedPage =
    setting.shouldRedirectToLastViewedPage ?? true;
  // console.log(tempSetting);
  try {
    await set(SETTING, JSON.stringify(tempSetting));
  } catch (e) {
    console.log(e);
  }
};
export const saveLastViewedBookId = async (bookId) => {
  await saveUserSetting({ lastViewed: { bookId } });
};
export const saveLastViewedChapter = async (chapter) => {
  await saveUserSetting({ lastViewed: { chapter } });
};
export const loadUserSettings = async () => {
  return await get(SETTING).then((value) => {
    // console.log(value);
    try {
      return JSON.parse(value);
    } catch (e) {
      return {
        lastViewed: { bookId: 1, chapter: 1 },
        shouldRedirectToLastViewedPage: true,
      };
    }
  });
};
export const clearCache = async (includeSetting = false) => {
  if (includeSetting) {
    await clear();
    console.log("clear cache with setting");
  } else {
    const value = await get(SETTING);
    await clear();
    await set(SETTING, value);
    console.log("clear cache without setting");
  }
};

/**
 *
 * @param language
 * @param bible_id
 * @param chapter
 * @return {Promise<BibleWordDataV3[]>}
 */
export const loadBibleWordDataV3 = async (language, bible_id, chapter) => {
  const fetch_url =
    `${URL_BIBLE_WORD_DATA_V3}/${language}/` +
    `${bible_id}`.padStart(2, "0") +
    "." +
    `${chapter}`.padStart(3, "0") +
    ".json";
  return await loader(fetch_url);
};
