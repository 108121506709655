const SubtitleLinkComponent = ({ text, onClick }) => {
  const handleClick = (e) => {
    e.stopPropagation();
    e.preventDefault();
    onClick();
  };
  return (
    <span className="subtitle-link clickable" onClick={handleClick}>
      {text}
    </span>
  );
};

export default SubtitleLinkComponent;
