import {
  boxesIntersect,
  useSelectionContainer,
} from "@air/react-drag-to-select";
import MODE from "../../constants/mode";
import {
  updateMode,
  updateSelectedWordIds,
} from "../../features/currents/currentsSlice";
import { useDispatch, useSelector } from "react-redux";

const WordDragSelectComponent = ({ selectableItems }) => {
  const dispatch = useDispatch();
  const { currentMode } = useSelector((state) => state.currents);

  const { DragSelection } = useSelectionContainer({
    eventsElement: document.getElementById("root"),
    onSelectionChange: (box) => {
      /**
       * Here we make sure to adjust the box's left and top with the scroll position of the window
       * @see https://github.com/AirLabsTeam/react-drag-to-select/#scrolling
       */
      const scrollAwareBox = {
        ...box,
        top: box.top + window.scrollY,
        left: box.left + window.scrollX,
      };

      // ignore top and left sidebar
      if (box.top + box.height < 60 || box.left + box.width < 170) {
        return;
      }

      const indexesToSelect = [];
      selectableItems.current.forEach((item, index) => {
        if (boxesIntersect(scrollAwareBox, item)) {
          if (item.className.includes("word-item")) indexesToSelect.push(index);
        }
      });

      dispatch(updateSelectedWordIds(indexesToSelect));
      if (currentMode !== MODE.SELECT && indexesToSelect.length === 0)
        dispatch(updateMode(MODE.NULL));
    },
    onSelectionStart: () => {
      dispatch(updateMode(MODE.SELECT));
    },
    onSelectionEnd: () => {},
    selectionProps: {
      style: {
        border: "2px dashed purple",
        borderRadius: 4,
        backgroundColor: "brown",
        opacity: 0.5,
      },
    },
    isEnabled: true,
  });
  return <DragSelection />;
};
export default WordDragSelectComponent;
