import "../../css/SelectBook.css";
import { withTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { updateIsNewBook } from "../../features/currents/currentsSlice";

const LiCheckOldOrNewBook = ({ t }) => {
  const dispatch = useDispatch();
  const { currentIsNewBook } = useSelector((state) => state.currents);
  const updateIsBookStatus = (e) => dispatch(updateIsNewBook(e.target.checked));

  return (
    <li className="check-box">
      <label className="check-box" onChange={updateIsBookStatus}>
        <input type="checkbox" checked={currentIsNewBook} readOnly={true} />
        <span className="checker" />
        <span className="text">{t("navigationBook.book.old")}</span>
        <span className="text">{t("navigationBook.book.new")}</span>
      </label>
    </li>
  );
};

export default withTranslation("page")(LiCheckOldOrNewBook);
