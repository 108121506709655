const MODE = {
  NULL: 0,
  SELECT: 1,
  SEARCH: 2,
  SETTING: 3,
  NOTICE: 4,
  MENU: 5,
  LOGIN: 6,
  FAVORITE: 7,
  LOADING: 8,
  TEST: 99,
};
Object.freeze(MODE);
export default MODE;
