import "../../css/NavigationBook.css";
import PropTypes from "prop-types";
import { useNavigate } from "react-router-dom";
import LiTitle from "./LiTitle";
import LiCheckOldOrNewBook from "./LiCheckOldOrNewBook";
import LiBook from "./LiBook";
import { useSelector, useDispatch } from "react-redux";
import {
  updateBookId,
  updateChapter,
  updateMode,
  updateSelectedWordIds,
} from "../../features/currents/currentsSlice";
import classNames from "classnames";
import MODE from "../../constants/mode";

const NavigationBookV2 = ({ books }) => {
  const navigate = useNavigate();
  const {
    currentBookId,
    currentChapter,
    currentSideOpen,
    currentIsNewBook,
    currentMode,
  } = useSelector((state) => state.currents);
  const dispatch = useDispatch();
  const className = classNames("navigation-book", {
    "active-all": currentSideOpen,
    "old-book": !currentIsNewBook,
    "new-book": currentIsNewBook,
  });

  const items = !currentIsNewBook ? books.slice(0, 39) : books.slice(39);
  const handleOnClick = (book) => {
    dispatch(updateSelectedWordIds([]));
    dispatch(updateBookId(book.id));

    if (currentMode === MODE.SELECT) {
      dispatch(updateMode(MODE.NULL));
    }
    if (currentChapter >= book.chapter) {
      dispatch(updateChapter(1));
    }
  };

  return (
    <div id="navigation-book" className={className}>
      <ul>
        <LiTitle onClick={() => navigate("/notice")} />
        <LiCheckOldOrNewBook />
        {items.map((book) => (
          <LiBook
            key={book.id}
            book={book}
            onClick={() => handleOnClick(book)}
            active={book.id === currentBookId}
            hidden={book.hidden}
          />
        ))}
      </ul>
    </div>
  );
};

NavigationBookV2.PropType = {
  t: PropTypes.func,
};

export default NavigationBookV2;
