import "../styles/css/EmbedPage.css";
import { useParams } from "react-router-dom";
import classNames from "classnames";
import { useEffect, useMemo, useState } from "react";
import { get_bible_book_data } from "../api/load_from_server";
import { loadBibleWordDataV3 } from "../utils/database";
import {
  convertDataV3ToElem,
  loadWordDataV3FromAddresses,
} from "../utils/word";
import { verseNumbersToTextType01 } from "../utils/text";

const RE_LIST = /^(?<book>\D{1,2})(?<chapter>\d{1,3}):?\[(?<lines>[\d\-,]+)]$/;

/**
 *
 * @param data {{book: {id: number, name: string}, chapter: number, lines: string[]}}
 * @return {JSX.Element}
 * @constructor
 */
const EmbedBlockComponent = ({ data }) => {
  const [title, setTitle] = useState("loading...");
  const [words, setWords] = useState([]);
  useEffect(() => {
    const { book, chapter, lines } = data;

    const addresses = [];
    lines.forEach((l) => {
      if (l.startsWith("-"))
        addresses.push({
          bookId: book.id,
          chapter: chapter,
          startLine: null,
          endLine: Number(l.slice(1)),
        });
      else if (l.endsWith("-"))
        addresses.push({
          bookId: book.id,
          chapter: chapter,
          startLine: Number(l.split("-")[0]),
          endLine: null,
        });
      else if (l.includes("-"))
        addresses.push({
          bookId: book.id,
          chapter: chapter,
          startLine: Number(l.split("-")[0]),
          endLine: Number(l.split("-")[1]),
        });
      else
        addresses.push({
          bookId: book.id,
          chapter: chapter,
          startLine: Number(l),
          endLine: Number(l),
        });
    });

    (async () => {
      const d = Array.from(
        await loadBibleWordDataV3("korean", book.id, chapter)
      );
      const maxLine = Math.max(...d.map((x) => x.line));
      const title = verseNumbersToTextType01(
        book.name,
        chapter,
        addresses.reduce(
          (p, c) =>
            c.startLine === c.endLine
              ? [...p, c.startLine]
              : c.startLine === null
              ? [
                  ...p,
                  ...Array.from({ length: c.endLine }, (_, index) => index + 1),
                ]
              : c.endLine === null
              ? [
                  ...p,
                  ...Array.from(
                    { length: maxLine - c.startLine },
                    (_, index) => index + c.startLine
                  ),
                ]
              : [
                  ...p,
                  ...Array.from(
                    { length: c.endLine - c.startLine + 1 },
                    (_, index) => index + c.startLine
                  ),
                ],
          []
        )
      );
      const words = await loadWordDataV3FromAddresses(addresses);

      setTitle(title);
      setWords(words);
    })();
  }, [data]);

  return (
    <div
      className={classNames("word-list", {
        "new-book": data.book.id > 39,
        "old-book": data.book.id <= 39,
      })}
    >
      <h3>{title}</h3>
      <ul>{convertDataV3ToElem(words, () => {}, [])}</ul>
    </div>
  );
};

const EmbedPage = () => {
  const { language } = useParams();
  const [datas, setDatas] = useState([]);
  const urlParams = useMemo(
    () => new URLSearchParams(window.location.search),
    []
  );

  useEffect(() => {
    const list = urlParams.get("l");
    (async () => {
      const books = Array.from(await get_bible_book_data("korean"));

      const datas = list
        .split(";")
        .filter((s) => s.trim())
        .map((s) => {
          const m = RE_LIST.exec(s);
          console.log(s, m);
          if (!m) return {};

          const book = books.find(
            (b) => b.short_name === m.groups.book || b.name === m.groups.book
          );
          const chapter = Number(m.groups.chapter);
          const lines = m.groups.lines
            .split(",")
            .map((l) => l.trim())
            .filter((l) => l);

          return { book, chapter, lines };
        })
        .filter((v) => v?.book && v?.chapter && v?.lines);
      setDatas(datas);
    })();
  }, [urlParams]);

  return (
    <div id={"embed-container"}>
      {datas.map((d) => (
        <EmbedBlockComponent data={d} />
      ))}
    </div>
  );
};

export default EmbedPage;
