export const DEFAULT_BOOK_DATA = {
  id: 1,
  name: "창세기",
  subName: "창",
  type: "old",
  chapter: [
    {
      id: 1,
      word: [
        {
          id: 1,
          word: "태초에 하나님이 천지를 창조하시니라",
        },
      ],
    },
  ],
};
export const DEFAULT_SEARCH_DATA = [
  {
    content: "태초에 하나님이 천지를 창조하시니라",
    verse: 1,
    chapter: 1,
    book: 1,
  },
];

export const DEFAULT_SEARCH_DATA_V3 = [
  {
    t: "",
    b: 1,
    c: 1,
    l: 1,
  },
];
// export const DEFAULT_CAPTION_DATA = [{
//     1: {
//         2: [{"book_idx": 1, "chapter_idx": 2, "word_idx": 12, "loc_idx": 0, "view": "1", "caption": "진주"}]
//     }
// }]
export const DEFAULT_BIBLE_BOOKS = [
  {
    id: 1,
    name: "창세기",
    short_name: "창",
    chapter: 50,
    chapter_suffix: "장",
    verse: "1533",
  },
];
