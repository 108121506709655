const CaptionLinkComponent = ({ text, onClick }) => {
  const handleClick = (e) => {
    e.stopPropagation();
    e.preventDefault();
    onClick();
  };
  return (
    <span className="caption-link clickable" onClick={handleClick}>
      {text}
    </span>
  );
};

export default CaptionLinkComponent;
