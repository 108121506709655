import classNames from "classnames";

const WordLineComponent = ({
  line,
  children,
  secondaryChildren = undefined,
  selected = false,
  onClick = undefined,
  ref = undefined,
}) => (
  <li
    className={classNames("word-item", { selected })}
    ref={ref}
    onClick={onClick}
  >
    <p>
      <span className="number">{line}</span>
      <span className="hidden"> </span>
      <span className="word" draggable="false" children={children} />
    </p>

    {secondaryChildren && (
      <p>
        <span className="word" draggable="false" children={secondaryChildren} />
      </p>
    )}
  </li>
);

export default WordLineComponent;
