import { createSlice } from "@reduxjs/toolkit";

/**
 *
 * @type {{books: Book[]}}
 */
const INITIAL_STATE = {
  books: [],
};

const globalsSlice = createSlice({
  name: "globals",
  initialState: INITIAL_STATE,
  reducers: {
    updateBooks(state, action) {
      state.books = [...action.payload];
    },
  },
});

export const { updateBooks } = globalsSlice.actions;
export default globalsSlice.reducer;
