import React from "react";
import LoadingComponent from "../components/LoadingComponent";
import * as database from "../utils/database";
import { useNavigate } from "react-router-dom";

export default function LoadingPage() {
  const navigate = useNavigate();
  React.useEffect(() => {
    (async () => {
      // 1. 데이터를 로딩한다.
      // const bookData = await load_from_server.get_bible_book_data();
      // const bibleData = await database.loadBible();

      // 2. 설정을 불러온다.
      const userSettings = await database.loadUserSettings();

      if (userSettings.shouldRedirectToLastViewedPage) {
        navigate(
          `/${userSettings.lastViewed.bookId}/${userSettings.lastViewed.chapter}`,
          { state: { userSettings } }
        );
      } else {
        navigate("/1/1", { state: { userSettings } });
      }
    })();
  }, [navigate]);

  return <LoadingComponent />;
}
