import "./css/App.css";
import "./css/Main.css";
import "react-contexify/dist/ReactContexify.css";
import "react-popper-tooltip/dist/styles.css";
import "react-toastify/dist/ReactToastify.css";

//https://www.hides.kr/965

import { useEffect, useState, useCallback } from "react";
import MainTopBar from "./components/MainTopBar";
import classNames from "classnames";
import { useSwipeable } from "react-swipeable";
import { copyToClipboard } from "./utils/copy";
import { toast } from "react-toastify";
import MODE from "./constants/mode";
import { DEFAULT_BIBLE_BOOKS, DEFAULT_SEARCH_DATA_V3 } from "./constants/dummy";
import {
  loadBibleSearchDataV3,
  loadBibleWordDataV3,
  loadUserSettings,
} from "./utils/database";
import {
  createCopyTextType01,
  createCopyTextType02,
  verseNumbersToTextType01,
} from "./utils/text";
import NavigationBookV2 from "./components/SideNavigationBook/NavigationBookV2";
import { get_bible_book_data } from "./api/load_from_server";
import NavigationChapterV2 from "./components/SideNavigationChapter/NavigationChapterV2";
import DialogComponent from "./components/DialogComponent";
import BackdropComponent from "./components/BackdropComponent";
import ContextComponent from "./components/ContextComponent";
import ToastComponent from "./components/ToastComponent";
import { useDispatch, useSelector } from "react-redux";
import {
  updateBookId,
  updateChapter,
  updateIsNewBook,
  updateMode,
  updateSearch,
  updateSelectedWordIds,
  updateSideOpen,
} from "./features/currents/currentsSlice";
import { convertDataV3ToElem, loadWordDataV3FromAddresses } from "./utils/word";
import LoadingComponent from "./components/LoadingComponent";
import { wrapOnClick } from "./utils/click";
import { useTranslation } from "react-i18next";
import WordContainer from "./container/WordContainer";
import { useMobileDetect } from "./utils/device";
import { updateBooks } from "./features/globals/globalsSlice";

const REGEX_SEARCH_V3 =
  /(?<bookName>\S+)\s?(?<chapter>\d{1,3})(장\s*|\s?:\s?)?(?<startLine>\d{1,3})?절?(-|~|에서)?\s*(?<endLine>\d{1,3})?(?<verse_split>([절,\s]+\d{1,3})+)?/gm;

function App() {
  const { t } = useTranslation("page");
  const settings = useSelector((state) => state.settings);
  const { language, secondaryLanguage, viewWithSecondaryLanguage } = settings;
  const {
    currentBookId,
    currentChapter,
    currentMode,
    currentIsNewBook,
    currentSideOpen,
    currentSelectedWordIds,
    currentSearch,
  } = useSelector((state) => state.currents);
  const { books } = useSelector((state) => state.globals);
  const detectMobile = useMobileDetect();
  const dispatch = useDispatch();
  const [bibleBooks, setBibleBooks] = useState(DEFAULT_BIBLE_BOOKS);

  const [searchDataV3, setSearchDataV3] = useState(DEFAULT_SEARCH_DATA_V3);
  const [searchResultV3, setSearchResultV3] = useState(DEFAULT_SEARCH_DATA_V3);
  const currentBook = bibleBooks.find((value) => value.id === currentBookId)
    ? bibleBooks.find((value) => value.id === currentBookId)
    : DEFAULT_BIBLE_BOOKS[0];
  const [currentWordDatas, setWordDatas] = useState([]);

  const [currentExtraWordDatas, setExtraWordDatas] = useState([]);

  const [dialogTitle, setDialogTitle] = useState("제목");
  const [dialogBody, setDialogBody] = useState(<span>본문</span>);
  const [dialogWords, setDialogWords] = useState([]);
  const [dialogMoveAddress, setDialogMoveAddress] = useState({
    bookId: 1,
    chapter: 1,
    startLine: 1,
    endLine: 1,
  });
  const toggleSideNav = () => dispatch(updateSideOpen(!currentSideOpen));
  document.getElementById("dialog-backdrop")
    ? (document.getElementById("dialog-backdrop").style.display =
        currentSideOpen ? "" : "none")
    : (() => {})();

  useEffect(() => {
    const prevMode = currentMode;
    if (prevMode === MODE.SEARCH && searchDataV3.length === 1) {
      dispatch(updateMode(MODE.LOADING));
      (async () => {
        setSearchDataV3(await loadBibleSearchDataV3(language));
        dispatch(updateMode(prevMode));
      })();
    }
  }, [currentMode, dispatch, searchDataV3.length]);

  const handlerSwipe = useSwipeable({
    onSwipedRight: () => dispatch(updateSideOpen(true)),
    onSwipedLeft: () => dispatch(updateSideOpen(false)),
    swipeDuration: 500,
    trackMouse: false,
  });

  // first load
  useEffect(() => {
    setSearchResultV3([]);
    (async () => {
      dispatch(updateBooks(await get_bible_book_data(language)));
      setBibleBooks(await get_bible_book_data(language));

      dispatch(updateIsNewBook(false));

      const userSetting = await loadUserSettings();
      if (userSetting.shouldRedirectToLastViewedPage) {
        dispatch(updateBookId(userSetting.lastViewed.bookId));
        dispatch(updateChapter(userSetting.lastViewed.chapter));
        dispatch(updateIsNewBook(userSetting.lastViewed.bookId > 39));
      }
    })();
  }, [dispatch]);

  useEffect(() => {
    setWordDatas([]);
    setExtraWordDatas([]);
    dispatch(updateIsNewBook(currentBook.id > 39));
    const timeoutId = setTimeout(async () => {
      const wordDatas = await loadBibleWordDataV3(
        language,
        currentBook.id,
        currentChapter
      );
      if (viewWithSecondaryLanguage) {
        const extraWordDatas = await loadBibleWordDataV3(
          secondaryLanguage,
          currentBook.id,
          currentChapter
        );
        setExtraWordDatas(extraWordDatas);
      }
      setWordDatas(wordDatas);

      if (currentMode === MODE.LOADING) dispatch(updateMode(MODE.NULL));
    }, 0);
    return () => clearTimeout(timeoutId);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    currentBook,
    currentChapter,
    dispatch,
    language,
    secondaryLanguage,
    viewWithSecondaryLanguage,
  ]);

  const handleCopyV2 = useCallback(
    (selectedWordsIds = undefined) => {
      const wordIds = Array.from(
        Array.isArray(selectedWordsIds)
          ? selectedWordsIds
          : currentSelectedWordIds
      ).sort((a, b) => a - b);

      if (wordIds.length === 0) return toast(t("app.copy.not_select"));
      const selectedWords = wordIds.map((i) => currentWordDatas[i]);

      executeCopy(
        settings.copyType,
        currentBook,
        currentChapter,
        selectedWords
      );
      dispatch(updateSelectedWordIds([]));
      dispatch(updateMode(MODE.NULL));
      toast(t("app.copy.success"));
    },
    [
      currentBook,
      currentChapter,
      currentSelectedWordIds,
      currentWordDatas,
      dispatch,
      settings.copyType,
      t,
    ]
  );

  //https://holycoders.com/snippets/react-js-detect-save-copy-keyboard-shortcuts/
  //https://devtrium.com/posts/how-keyboard-shortcut
  const handleMainKeyDown = useCallback(
    (event) => {
      let charCode = String.fromCharCode(event.which).toLowerCase();
      if ((event.ctrlKey || event.metaKey) && charCode === "c") {
        if (currentMode === MODE.SELECT) {
          event.preventDefault();
          handleCopyV2();
        }
      } else if ((event.ctrlKey || event.metaKey) && charCode === "k") {
        if (currentMode === MODE.SELECT) {
          event.preventDefault();
          handleCopyToUrl();
        }
      } else if ((event.ctrlKey || event.metaKey) && charCode === "f") {
        if (currentMode === MODE.NULL) {
          event.preventDefault();
          dispatch(updateMode(MODE.SEARCH));
        }
      } else if (event.key === "Escape") {
        dispatch(updateSelectedWordIds([]));
        dispatch(updateMode(MODE.NULL));
      }
    },
    [currentMode, dispatch, handleCopyV2]
  );
  useEffect(() => {
    // attach the event listener
    document.addEventListener("keydown", handleMainKeyDown);

    // remove the event listener
    return () => {
      document.removeEventListener("keydown", handleMainKeyDown);
    };
  }, [handleMainKeyDown]);

  const handleIcon = () => {
    switch (currentMode) {
      case MODE.NULL:
        return dispatch(updateMode(MODE.SEARCH));
      case MODE.SELECT:
        return handleCopyV2();
      case MODE.SEARCH:
        return (
          !executeSearchIfAddress() &&
          executeSearch(settings.searchType, searchDataV3, searchKeywords)
        );
      default:
        return;
    }
  };

  const handleLink = async (link) => {
    const dialogAddresses = [];

    for (let i = link.start.chapter; i <= link.end.chapter; i++) {
      if (i === link.start.chapter)
        if (link.start.chapter === link.end.chapter)
          dialogAddresses.push({
            bookId: link.start.bookId,
            chapter: i,
            startLine: link.start.line,
            endLine: link.end.line,
          });
        else
          dialogAddresses.push({
            bookId: link.start.bookId,
            chapter: i,
            startLine: link.start.line,
            endLine: null,
          });
      else if (i === link.end.chapter)
        dialogAddresses.push({
          bookId: link.start.bookId,
          chapter: i,
          startLine: null,
          endLine: link.end.line,
        });
      else
        dialogAddresses.push({
          bookId: link.start.bookId,
          chapter: i,
          startLine: null,
          endLine: null,
        });
    }

    const dialogWords = await loadWordDataV3FromAddresses(dialogAddresses);

    const bookName = bibleBooks.find(
      (book) => book.id === dialogAddresses[0].bookId
    ).short_name;
    const title =
      dialogAddresses.length === 1
        ? dialogAddresses[0].startLine === dialogAddresses[0].endLine
          ? `${bookName} ${dialogAddresses[0].chapter}:${dialogAddresses[0].startLine}`
          : `${bookName} ${dialogAddresses[0].chapter}:${dialogAddresses[0].startLine}-${dialogAddresses[0].endLine}`
        : `${bookName} ${dialogAddresses[0].chapter}:${
            dialogAddresses[0].startLine
          }-${dialogAddresses[dialogAddresses.length - 1].chapter}:${
            dialogAddresses[dialogAddresses.length - 1].endLine
          }`;
    setDialogTitle(title);
    const className = classNames("word-list", {
      "new-book": currentIsNewBook,
      "old-book": !currentIsNewBook,
    });
    setDialogWords(dialogWords);
    setDialogBody(
      <div className={className}>
        <ul>{convertDataV3ToElem(dialogWords, handleLink, [])}</ul>
      </div>
    );
    setDialogMoveAddress(dialogAddresses[0]);
    if (!document.querySelector("#dialog").open)
      document.querySelector("#dialog").showModal();
  };

  const handleDialogEnter = () => {
    const { bookId, chapter } = dialogMoveAddress;

    dispatch(updateBookId(bookId));
    dispatch(updateChapter(chapter));
  };

  const searchKeywords = currentSearch.split(" ");
  const executeSearch = useCallback(
    (searchType, searchData, searchKeywords) => {
      setSearchResultV3(
        searchType === "searchType01" || searchType === "searchType02"
          ? searchData.filter((s) =>
              searchKeywords.every((k) => s.t.includes(k))
            )
          : []
      );
    },
    []
  );
  useEffect(() => {
    if (currentMode === MODE.SEARCH && detectMobile.isDesktop())
      executeSearch(settings.searchType, searchDataV3, searchKeywords);
  }, [
    currentMode,
    detectMobile,
    executeSearch,
    searchDataV3,
    searchKeywords,
    settings.searchType,
  ]);
  const searchResultBookIdsV3 = new Set(searchResultV3.map((r) => r.b));
  const searchResultBooksV3 = bibleBooks.map((book) => {
    return {
      ...book,
      hidden:
        currentMode === MODE.SEARCH
          ? !searchResultBookIdsV3.has(book.id)
          : false,
    };
  });
  const searchResultChaptersV3 =
    currentMode === MODE.SEARCH
      ? searchResultV3
          .filter((res) => res.b === currentBook.id)
          .reduce((prev, curr) => {
            if (prev.includes(curr.c)) {
              return prev;
            }
            return [...prev, curr.c];
          }, [])
      : [-1];

  const handleCopyCancel = () => {
    dispatch(updateSelectedWordIds([]));
    dispatch(updateMode(MODE.NULL));
  };

  const handleCopyDialog = () => {
    const currentBook = bibleBooks.find(
      (book) => book.id === dialogMoveAddress.bookId
    );
    const currentChapter = dialogMoveAddress.chapter;
    const selectedWords = dialogWords.filter((word) => word.type === "word");

    executeCopy(settings.copyType, currentBook, currentChapter, selectedWords);
    dispatch(updateSelectedWordIds([]));
    dispatch(updateMode(MODE.NULL));
    toast(t("app.copy.success"));
  };
  const executeCopy = (
    copyType,
    currentBook,
    currentChapter,
    selectedWords
  ) => {
    const text =
      copyType === "copyType01"
        ? createCopyTextType01(currentBook, currentChapter, selectedWords)
        : copyType === "copyType02"
        ? createCopyTextType02(currentBook, currentChapter, selectedWords)
        : "";
    copyToClipboard(text);
  };
  const handleSearchKeyDownV2 = (e) => {
    if (e.key !== "Enter") return;
    e.preventDefault();
    e.stopPropagation();
    if (!executeSearchIfAddress())
      executeSearch(settings.searchType, searchDataV3, searchKeywords);
  };
  const executeSearchIfAddress = () => {
    const m = REGEX_SEARCH_V3.exec(currentSearch);
    if (!m) return;

    const bookName = m.groups.bookName;
    const book = bibleBooks.find(
      (book) => book.short_name === bookName || book.name === bookName
    );
    if (!book) return;
    const chapter = Number(m.groups.chapter);
    if (!chapter || chapter > book.chapter) {
      if (book.chapter === 1)
        toast(t("app.copy.error.index.one", { bookName: book.name }));
      else
        toast(
          t("app.copy.error.index.many", {
            bookName: book.name,
            chapter: book.chapter,
          })
        );
      return;
    }

    const startLine = m.groups.startLine ? Number(m.groups.startLine) : 1;
    const endLine = m.groups.endLine ? Number(m.groups.endLine) : 1;

    dispatch(updateSearch(""));
    dispatch(updateBookId(book.id));
    dispatch(updateChapter(chapter));
    dispatch(updateMode(MODE.NULL));
    return true;
  };
  const handleCopyToUrl = () => {
    const wordIds = Array.from(currentSelectedWordIds).sort((a, b) => a - b);

    if (wordIds.length === 0) return toast(t("app.copy.not_select"));
    const selectedWords = wordIds.map((i) => currentWordDatas[i]);

    let text = verseNumbersToTextType01(
      currentBook.short_name,
      currentChapter,
      selectedWords.map((word) => word.line)
    );
    text = text.replaceAll(/\s/g, "").replaceAll("~", "-");
    // 구분자인 ';'이 마지막에 있으면 링크 처리가 안되므로 제거
    text = text.split(":")[0] + ":[" + text.split(":")[1].trim() + "]";

    copyToClipboard(window.location.origin + "/embed/ko?l=" + text);
    toast(t("app.copy.success"));
  };
  const handleCopySecondaryLanguage = () => {
    const wordIds = Array.from(currentSelectedWordIds).sort((a, b) => a - b);

    if (wordIds.length === 0) return toast(t("app.copy.not_select"));
    else if (!viewWithSecondaryLanguage)
      return toast(t("app.copy.need.activate_secondary_language"));

    const selectedWords = wordIds.map((i) => currentExtraWordDatas[i]);

    executeCopy(settings.copyType, currentBook, currentChapter, selectedWords);
    dispatch(updateSelectedWordIds([]));
    dispatch(updateMode(MODE.NULL));
    toast(t("app.copy.success"));
  };

  if (currentMode === MODE.LOADING) return <LoadingComponent />;
  return (
    <div className="Book" {...handlerSwipe} onClick={wrapOnClick(() => {})}>
      <NavigationBookV2 books={searchResultBooksV3} />
      <NavigationChapterV2
        book={currentBook}
        show_list={searchResultChaptersV3}
      />

      {/*Main*/}
      <div
        className={classNames("main", {
          "active-all": currentSideOpen,
          "old-book": !currentIsNewBook,
          "new-book": currentIsNewBook,
        })}
      >
        <MainTopBar
          handleSearchKeyDown={handleSearchKeyDownV2}
          handleIcon={handleIcon}
          book={currentBook}
        />

        {currentWordDatas.length === 0 && <div className="lds-dual-ring"></div>}
        {(currentMode === MODE.NULL ||
          currentMode === MODE.SEARCH ||
          currentMode === MODE.SELECT) && (
          <WordContainer
            words={currentWordDatas}
            extraWords={currentExtraWordDatas}
            handleLink={handleLink}
          />
        )}
      </div>
      <div
        className={classNames("sub", {
          "active-all": currentSideOpen,
          "old-book": !currentIsNewBook,
          "new-book": currentIsNewBook,
        })}
      ></div>
      <ToastComponent />
      <DialogComponent
        title={dialogTitle}
        body={dialogBody}
        handleClose={() => {}}
        handleEnter={handleDialogEnter}
        handleCopy={handleCopyDialog}
      />
      <ContextComponent
        handleCopy={handleCopyV2}
        handleCopyToUrl={handleCopyToUrl}
        handleCopyCancel={handleCopyCancel}
        handleCopySecondaryLanguage={handleCopySecondaryLanguage}
      />
      <BackdropComponent onClick={toggleSideNav} />
    </div>
  );
}

export default App;
