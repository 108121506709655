const LiTitle = ({onClick}) => (<li onClick={onClick}>
    <span className="clickable">
        <span className="icon">
            <ion-icon name="book-outline"/>
            <span className="version">{document.querySelector('meta[name="version"]').content}</span>
        </span>
        <span className="title">Bible</span>
        <span>{document.querySelector('meta[name="version"]').content}</span>
    </span>
</li>);

export default LiTitle;