import { createSlice } from "@reduxjs/toolkit";
import MODE from "../../constants/mode";
import {
  saveLastViewedBookId,
  saveLastViewedChapter,
} from "../../utils/database";
const INITIAL_STATE = {
  currentBookId: 1,
  currentChapter: 1,
  currentMode: MODE.LOADING,
  currentSideOpen: false,
  currentIsNewBook: true,
  currentSelectedWordIds: [],
  currentSearch: "",
};

const currentsSlice = createSlice({
  name: "currents",
  initialState: INITIAL_STATE,
  reducers: {
    updateBookId(state, action) {
      state.currentBookId = action.payload;
      (async () => {
        await saveLastViewedBookId(action.payload);
      })();
    },
    updateChapter(state, action) {
      state.currentChapter = action.payload;
      (async () => {
        await saveLastViewedChapter(action.payload);
      })();
    },
    updateMode(state, action) {
      // if (state.currentMode !== MODE.LOADING && action.payload === MODE.NULL)
      // throw new Error("error");

      if (!Object.values(MODE).includes(action.payload)) {
        console.warn("Unknown Mode:", action.payload);
        state.currentMode = MODE.NULL;
      } else {
        state.currentMode = action.payload;
      }
    },
    updateSideOpen(state, action) {
      state.currentSideOpen = action.payload;
    },
    updateIsNewBook(state, action) {
      state.currentIsNewBook = action.payload;
    },
    updateSelectedWordIds(state, action) {
      state.currentSelectedWordIds = [...action.payload];
    },
    updateSearch(state, action) {
      state.currentSearch = action.payload;
    },
  },
});

export const {
  updateBookId,
  updateChapter,
  updateMode,
  updateSideOpen,
  updateIsNewBook,
  updateSelectedWordIds,
  updateSearch,
} = currentsSlice.actions;
export default currentsSlice.reducer;
