import { useEffect } from "react";
import "../styles/css/aqua.min.css";
import "../css/Dialog.css";

const DialogComponent = ({
  handleClose,
  handleEnter,
  handleCopy,
  title,
  body,
}) => {
  const onClose = () => {
    document.querySelector("#dialog").close();
    handleClose();
  };
  const onEnter = () => {
    document.querySelector("#dialog").close();
    handleEnter();
  };
  const onCopy = () => {
    document.querySelector("#dialog").close();
    handleCopy();
  };
  useEffect(() => {
    let observer = new IntersectionObserver(
      (entries, observer) => {
        entries.forEach((entry) => {
          if (entry.intersectionRatio === 1) {
            // document.getElementById("dialog-backdrop").style.display = "";
          } else {
            // document.getElementById("dialog-backdrop").style.display = "none";
          }
          // console.log(entry)
        });
      },
      {
        // root: document.querySelector("#dialog"),
        rootMargin: "0px",
        threshold: 1.0,
      }
    );
    observer.observe(document.querySelector("#dialog"));

    return () => {
      observer.disconnect();
    };
  }, []);

  return (
    <dialog
      className="dialog text-center shadow-lg"
      id="dialog"
      style={{
        "--dialog-padding": "0.5rem",
        "--dialog-width": "50rem",
        border: "transparent",
      }}
    >
      <div className="dialog-content">
        <div className="dialog-header">
          <div className="dialog-title font-bold">{title}</div>
        </div>
        <div className="dialog-body" style={{ textAlign: "start" }}>
          {body}
        </div>
        <div className="dialog-footer whitespace-no-wrap">
          <button className="btn btn-success mr-4" onClick={onCopy}>
            복사
          </button>
          <button className="btn btn-primary mr-4" onClick={onEnter}>
            이동
          </button>
          <button className="btn" onClick={onClose}>
            닫기
          </button>
        </div>
      </div>
    </dialog>
  );
};

export default DialogComponent;
