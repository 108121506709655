import "../css/Words.css";
import { useDispatch, useSelector } from "react-redux";
import classNames from "classnames";
import { convertDataV3ToElem } from "../utils/word";
import { useEffect, useRef } from "react";
import WordDragSelectComponent from "../components/WordComponentV3/WordDragSelectComponent";
import {
  updateMode,
  updateSelectedWordIds,
} from "../features/currents/currentsSlice";
import { contextMenu } from "react-contexify";
import { WORD_CONTEXT_MENU_ID } from "../constants/contextMenu";
import MODE from "../constants/mode";

const WordContainer = ({
  words,
  extraWords,
  handleLink,
  scrollTargetLine = 1,
}) => {
  const dispatch = useDispatch();
  const scrollTargetElem = useRef(null);
  const selectableItems = useRef([]);
  const elementsContainerRef = useRef(null);
  const { currentIsNewBook, currentSelectedWordIds, currentSearch } =
    useSelector((state) => state.currents);

  const searchKeywords = currentSearch.split(" ");

  const className = classNames("word-list", {
    "new-book": currentIsNewBook,
    "old-book": !currentIsNewBook,
  });

  const extras = words.map((word, index) => {
    return {
      ref: undefined,
      selected: currentSelectedWordIds.includes(index),
      onClick: () => handleLineClick(index),
      searchKeywords,
      secondaryChildren: extraWords?.find((extra) => extra.line === word.line),
      // compare: extraWords?.find((extra) => extra.line === word.line)
      //   ?.contents[0].text,
    };
  });
  const onContextMenu = (e) =>
    contextMenu.show({
      id: WORD_CONTEXT_MENU_ID,
      event: e,
    });

  useEffect(() => {
    if (elementsContainerRef.current) {
      selectableItems.current = [];

      Array.from(elementsContainerRef.current.children).forEach((item) => {
        if (
          !item.className.includes("word-item") &&
          !item.className.includes("subtitle")
        )
          return;

        const { left, top, width, height } = item.getBoundingClientRect();
        const className = item.className;

        selectableItems.current.push({
          left,
          top: top + window.scrollY,
          width,
          height,
          className,
        });
      });
    }
  }, [words, extraWords]);

  const handleLineClick = (lineIndex) => {
    const selected = currentSelectedWordIds.includes(lineIndex);
    const newSelectedWordIds = selected
      ? [...currentSelectedWordIds.filter((i) => i !== lineIndex)]
      : [...currentSelectedWordIds, lineIndex];
    dispatch(updateSelectedWordIds(newSelectedWordIds));
    if (newSelectedWordIds.length === 0) dispatch(updateMode(MODE.NULL));
    else dispatch(updateMode(MODE.SELECT));
  };
  return (
    <div
      id="word-component"
      className={className}
      onContextMenu={onContextMenu}
    >
      <ul id="elements-container" ref={elementsContainerRef}>
        <WordDragSelectComponent selectableItems={selectableItems} />
        {convertDataV3ToElem(words, handleLink, extras)}
      </ul>
    </div>
  );
};
export default WordContainer;
