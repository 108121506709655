import { createSlice } from "@reduxjs/toolkit";
const INITIAL_STATE = {
  lastScripture: {
    bookNo: 1,
    chapterNo: 1,
    verse: 1,
  },
  language: "korean",
  copyType: "copyType01",
  searchType: "searchType01",
  saveLastScripture: true,
  fontSize: 16,
  searchWithSubtitle: true,
  searchWithCaption: true,
  secondaryLanguage: "",
  viewWithSecondaryLanguage: false,
};

const settingsSlice = createSlice({
  name: "settings",
  initialState: INITIAL_STATE,
  reducers: {
    updateLastScripture(state, action) {
      state.lastScripture = {
        bookNo: action.payload.bookNo,
        chapterNo: action.payload.chapterNo,
        verse: action.payload.verse,
      };
    },
    updateLanguage(state, action) {
      state.language = action.payload;
    },
    updateCopyType(state, action) {
      state.copyType = action.payload;
    },
    updateSearchType(state, action) {
      state.searchType = action.payload;
    },
    toggleSaveLastScripture(state, action) {
      state.saveLastScripture = !state.saveLastScripture;
    },
    updateFontSize(state, action) {
      state.fontSize = action.payload;
      // console.log(document.getElementsByTagName("html")[0])
      document.getElementsByTagName("html")[0].style.fontSize =
        state.fontSize + "px";
      // document.getElementById("root").style.fontSize = state.fontSize + "px";
    },
    toggleSearchWithSubtitle(state, action) {
      state.searchWithSubtitle = !state.searchWithSubtitle;
    },
    toggleSearchWithCaption(state, action) {
      state.searchWithCaption = !state.searchWithCaption;
    },
    updateSecondaryLanguage(state, action) {
      state.secondaryLanguage = action.payload;
    },
    toggleViewWIthSecondaryLanguage(state, action) {
      state.viewWithSecondaryLanguage = !state.viewWithSecondaryLanguage;
    },
  },
});

export const {
  updateLastScripture,
  updateLanguage,
  updateCopyType,
  updateSearchType,
  toggleSaveLastScripture,
  updateFontSize,
  toggleSearchWithSubtitle,
  toggleSearchWithCaption,
  updateSecondaryLanguage,
  toggleViewWIthSecondaryLanguage,
} = settingsSlice.actions;
export default settingsSlice.reducer;
