const UUID = crypto.randomUUID();

function fetchClickRecord({
  w,
  h,
  clientX,
  clientY,
  pageX,
  pageY,
  screenX,
  screenY,
  button,
}) {
  return;
  fetch("http://localhost:8080/api/record/click", {
    method: "post",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify({
      UUID,
      w,
      h,
      clientX,
      clientY,
      pageX,
      pageY,
      screenX,
      screenY,
      button,
    }),
  });
  // .then((r) => console.log(r))
  // .catch((reason) => console.error(reason));
}

/**
 *
 * @param func
 */
export function wrapOnClick(func) {
  return (event) => {
    if (event.type === "click") {
    }
    const w = window.innerWidth;
    const h = window.innerHeight;
    const { clientX, clientY, pageX, pageY, screenX, screenY, button } = event;
    fetchClickRecord({
      w,
      h,
      clientX,
      clientY,
      pageX,
      pageY,
      screenX,
      screenY,
      button,
    });
    // console.log({ UUID });
    return func(event);
  };
}
export function wrapOnTouch(func) {
  return (event) => {
    console.log(event);
    console.log(event.type);
    return func(event);
  };
}
