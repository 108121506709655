import Stack from "@mui/material/Stack";
import {
  AppBar,
  FormControl,
  FormControlLabel,
  IconButton,
  InputLabel,
  MenuItem,
  Select,
  Switch,
  Toolbar,
  Typography,
} from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import {
  toggleViewWIthSecondaryLanguage,
  updateLanguage,
  updateSecondaryLanguage,
} from "../features/settings/settingsSlice";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { useNavigate } from "react-router-dom";

const AVAILABLE_LANGUAGE = [
  {
    value: "korean",
    children: "한글",
  },
  {
    value: "english",
    children: "English",
  },
];

export default function SettingPage() {
  const { t } = useTranslation("page");
  const {
    language,
    copyType,
    searchType,
    saveLastScripture,
    fontSize,
    secondaryLanguage,
    viewWithSecondaryLanguage,
  } = useSelector((state) => state.settings);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const handlePrimaryLanguage = (event, child) => {
    const primaryLanguage = child.props.value;
    // 기본 언어와 추가 언어가 같을 때
    if (primaryLanguage === secondaryLanguage) {
      // 추가 언어 비활성화
      dispatch(updateSecondaryLanguage(""));
      // 추가 언어 보기가 참일 때
      if (viewWithSecondaryLanguage)
        // 추가 언어 보기를 거짓으로 변경
        dispatch(toggleViewWIthSecondaryLanguage());
    }
    dispatch(updateLanguage(primaryLanguage));
  };
  const handleSecondaryLanguage = (event, child) => {
    const secondaryLanguage = child.props.value;
    if (secondaryLanguage) {
      // 추가 언어 보기를 참으로 변경
      if (!viewWithSecondaryLanguage)
        dispatch(toggleViewWIthSecondaryLanguage());
    } else {
      // 추가 언어 보기를 거짓으로 변경
      if (viewWithSecondaryLanguage)
        dispatch(toggleViewWIthSecondaryLanguage());
    }
    dispatch(updateSecondaryLanguage(secondaryLanguage));
  };
  const handleViewSecondaryLanguage = (event) => {
    if (secondaryLanguage) dispatch(toggleViewWIthSecondaryLanguage());
    else alert("추가 언어가 설정되지 않았습니다.");
  };
  const handleOnClickBack = (event) => {
    navigate("/");
  };

  return (
    <div>
      <AppBar position={"sticky"}>
        <Toolbar>
          <IconButton
            size={"large"}
            edge={"start"}
            color={"inherit"}
            aria-label={"return"}
            sx={{ mr: 2 }}
            onClick={handleOnClickBack}
          >
            <ArrowBackIcon />
          </IconButton>
          <Typography variant={"h5"}>설정</Typography>
        </Toolbar>
      </AppBar>
      <Stack spacing={1} style={{ padding: "1rem" }}>
        <Typography variant={"h6"}>기본 설정</Typography>
        <FormControl fullWidth>
          <InputLabel id="setting-select-primary-language-label">
            기본 언어
          </InputLabel>
          <Select
            labelId="setting-select-primary-language-label"
            id="setting-select-primary-language"
            value={language}
            label="기본 언어"
            onChange={handlePrimaryLanguage}
          >
            {AVAILABLE_LANGUAGE.map((item, key) => (
              <MenuItem key={key} value={item.value}>
                {item.children}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
        <FormControl fullWidth>
          <InputLabel id="setting-select-secondary-language-label">
            추가 언어
          </InputLabel>
          <Select
            labelId="setting-select-secondary-language-label"
            id="setting-select-secondary-language"
            value={secondaryLanguage}
            label="추가 언어"
            onChange={handleSecondaryLanguage}
          >
            <MenuItem value={""}>선택 안함</MenuItem>
            {AVAILABLE_LANGUAGE.filter((item) => item.value !== language).map(
              (item, key) => (
                <MenuItem key={key} value={item.value}>
                  {item.children}
                </MenuItem>
              )
            )}
          </Select>
        </FormControl>
        <FormControlLabel
          control={
            <Switch
              checked={viewWithSecondaryLanguage}
              onChange={handleViewSecondaryLanguage}
            />
          }
          label={"추가 언어 보기"}
        />
      </Stack>
    </div>
  );
}
