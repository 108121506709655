/**
 * chatGPT 코드와 비교해서 내가 짠 코드가 작동 속도가 월등히 빠름
 * 해당 코드는 array에 모든 index를 저장 후 한꺼번에 합성하고
 * 내 코드는 즉시 코드를 합성함
 *
 * @param wordIds {Array<number>}
 * @return {string}
 */
export const makeFromWordId = (wordIds) => {
  if (wordIds.length === 0) return "";
  else if (wordIds.length === 1) return `${wordIds[0]}`;

  const maxLoopIdx = wordIds.length - 1;
  let prevWordId = wordIds[0];
  let latestWordIdStart = prevWordId;
  let text = `${prevWordId}`;

  wordIds.forEach((wordId, idx) => {
    if (prevWordId === wordId) {
    } else if (prevWordId + 1 === wordId) {
      if (idx === maxLoopIdx) {
        text += ` ~ ${wordId}`;
      }
    } else if (latestWordIdStart === prevWordId) {
      text += `, ${wordId}`;
      latestWordIdStart = wordId;
    } else {
      text += ` ~ ${prevWordId}, ${wordId}`;
      latestWordIdStart = wordId;
    }
    prevWordId = wordId;
  });
  return text;
};

/**
 *
 * @param bookName {string}
 * @param chapter {number}
 * @param verses {Array<string>}
 *
 * @return {string}
 */
export const verseNumbersToTextType01 = (bookName, chapter, verses) => {
  if (verses.length === 1) return `${bookName} ${chapter}:${verses[0]}\n\n`;

  const ids = [];
  verses.forEach((v) => {
    if (Number.isInteger(Number(v))) {
      ids.push(Number(v));
    } else {
      const [n1, n2] = v.split("-");
      ids.push(Number(n1));
      ids.push(Number(n2));
    }
  });
  ids.sort((a, b) => a - b);
  return `${bookName} ${chapter}: ${makeFromWordId(ids)}\n\n`;
};

const createCopyWordTextData = (selectedWords) => {
  const result = selectedWords.map((word) => {
    const verse = word.contents
      .filter((content) => content.type !== "captions")
      .map((content) => content.text)
      .join("")
      .trim();
    return {
      line: word.line,
      verse: verse,
    };
  });
  result.forEach((d, i) => {
    if (d.verse) return;
    let prevD = result[i - 1];
    if (!prevD.verse) prevD = result[i - 2];
    prevD.line = `${prevD.line}`.split("-")[0] + `-${d.line}`;
  });
  return result;
};
export const createCopyTextType01 = (
  currentBook,
  currentChapter,
  selectedWords
) =>
  `${verseNumbersToTextType01(
    currentBook.name,
    currentChapter,
    selectedWords.map((word) => word.line)
  )}${createCopyWordTextData(selectedWords)
    .filter((d) => d.verse)
    .map((d) => `${d.line} ${d.verse}\n`)
    .join("")}`;
export const createCopyTextType02 = (
  currentBook,
  currentChapter,
  selectedWords
) =>
  `${createCopyWordTextData(selectedWords)
    .filter((d) => d.verse)
    .map(
      (d) =>
        `${currentBook.short_name} ${currentChapter}:${d.line} ${d.verse}\n`
    )
    .join("")}`;
