import { configureStore } from "@reduxjs/toolkit";
import settingsReducer from "./features/settings/settingsSlice";
import currentsReducer from "./features/currents/currentsSlice";
import globalsReducer from "./features/globals/globalsSlice";

export const store = configureStore({
  reducer: {
    settings: settingsReducer,
    currents: currentsReducer,
    globals: globalsReducer,
  },
});
