import CaptionLinkComponent from "./CaptionLinkComponent";

const CaptionComponent = ({ text, captions, handleLink }) => (
  <span className="caption">
    {`〔 ${text} `}
    {captions.map((caption, index) => {
      switch (caption.type) {
        case "text":
          return caption.text;
        case "link":
          const link = {
            start: caption.link[0],
            end: caption.link[caption.link.length - 1],
          };
          return (
            <CaptionLinkComponent
              key={index}
              text={caption.text}
              onClick={() => handleLink(link)}
            />
          );
        default:
          console.warn(caption);
          return "";
      }
    })}
    {" 〕"}
  </span>
);

export default CaptionComponent;
