export const get_bible_book_data = async (lang) => {
  const response = await fetch(`/static/bible_data.v3/${lang}/books.json`);
  return await response.json();
};

export const get_caption_by_id = (bible_id, chapter, caption_id) => {
  const key =
    `${bible_id}`.padStart(3, "0") + `${chapter}`.padStart(3, "0") + caption_id;
  return [
    {
      id: 1,
      group_id: "048003D_213044_1",
      content_type: "caption",
      content: "헬, 수응하고",
      link_id: "",
    },
  ];
};
