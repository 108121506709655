import classNames from "classnames";

const LiChapter = ({chapter, active, onClick, hidden = false}) => (
    <li onClick={onClick} className={classNames({hovered: active})} style={{display: hidden ? "none" : ""}} >
        <span className="clickable">
            <span className="title">{chapter}</span>
        </span>
    </li>
);

export default LiChapter;
