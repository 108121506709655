import "../../css/NavigationChapter.css";
import LiChapter from "./LiChapter";
import LiRedo from "./LiRedo";
import { useDispatch, useSelector } from "react-redux";
import {
  updateChapter,
  updateMode,
  updateSelectedWordIds,
  updateSideOpen,
} from "../../features/currents/currentsSlice";
import { withTranslation } from "react-i18next";
import classNames from "classnames";
import MODE from "../../constants/mode";
import { useEffect } from "react";

const NavigationChapterV2 = ({ book, show_list = [-1], t }) => {
  const { currentSideOpen, currentIsNewBook, currentChapter, currentMode } =
    useSelector((state) => state.currents);
  const dispatch = useDispatch();
  const className = classNames("navigation-chapter", {
    "active-all": currentSideOpen,
    "old-book": !currentIsNewBook,
    "new-book": currentIsNewBook,
  });

  useEffect(() => {
    if (
      show_list.length !== 0 &&
      !show_list.includes(currentChapter) &&
      (show_list.length !== 1 || show_list[0] !== -1)
    ) {
      dispatch(updateChapter(show_list[0]));
    }
  }, [currentChapter, dispatch, show_list]);

  const handleOnClick = (chapter) => {
    dispatch(updateSelectedWordIds([]));
    dispatch(updateSideOpen(false));
    dispatch(updateChapter(chapter));

    if (currentMode === MODE.SELECT) {
      dispatch(updateMode(MODE.NULL));
    }
  };
  const items = new Array(book.chapter).fill(null).map((_, i) => {
    return {
      chapter: i + 1 + book.chapter_suffix,
      active: i + 1 === currentChapter,
      onClick: () => handleOnClick(i + 1),
      hidden:
        show_list.length === 1 && show_list[0] === -1
          ? false
          : !show_list.includes(i + 1),
    };
  });

  return (
    <div className={className}>
      <ul>
        <LiRedo t={t} onClick={() => dispatch(updateSideOpen(false))} />
        {items.map((item) => (
          <LiChapter key={item.chapter} {...item} />
        ))}
      </ul>
    </div>
  );
};

export default withTranslation("page")(NavigationChapterV2);
