import { Item, Menu, Separator, Submenu, RightSlot } from "react-contexify";

const WORD_CONTEXT_MENU_ID = "word-context-menu-id";

const ContextComponent = ({
  handleCopy,
  handleCopyToUrl,
  handleCopyCancel,
  handleCopySecondaryLanguage,
}) => {
  return (
    <Menu id={WORD_CONTEXT_MENU_ID}>
      <Item onClick={handleCopy}>
        복사<RightSlot>Ctrl + C</RightSlot>
      </Item>
      <Item onClick={handleCopySecondaryLanguage}>
        추가 언어 복사<RightSlot>Ctrl + C</RightSlot>
      </Item>
      <Item onClick={handleCopyToUrl}>
        링크 복사<RightSlot>Ctrl + K</RightSlot>
      </Item>
      <Item onClick={handleCopyCancel}>
        선택 취소<RightSlot>Esc</RightSlot>
      </Item>

      {/*<Item onClick={handleItemClick}>*/}
      {/*    링크*/}
      {/*</Item>*/}
      {/*<Separator />*/}
      {/*<Item disabled>Disabled</Item>*/}
      {/*<Separator />*/}
      {/*<Submenu label="Submenu">*/}
      {/*  <Item onClick={handleItemClick}>*/}
      {/*    Sub Item 1*/}
      {/*  </Item>*/}
      {/*  <Item onClick={handleItemClick}>Sub Item 2</Item>*/}
      {/*</Submenu>*/}
    </Menu>
  );
};

export default ContextComponent;
