import "../css/MainTopBar.css";
import { useRef } from "react";
import MODE from "../constants/mode";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  toggleSearchWithCaption,
  toggleSearchWithSubtitle,
} from "../features/settings/settingsSlice";
import { withTranslation } from "react-i18next";
import {
  updateMode,
  updateSearch,
  updateSideOpen,
} from "../features/currents/currentsSlice";

const MainTopBar = ({ handleSearchKeyDown, handleIcon, book, t }) => {
  const { searchWithSubtitle, searchWithCaption } = useSelector(
    (state) => state.settings
  );
  const {
    currentChapter,
    currentMode,
    currentSideOpen,
    currentSelectedWordIds,
    currentSearch,
  } = useSelector((state) => state.currents);
  const dispatch = useDispatch();
  const handleSearchWIthSubtitle = () => dispatch(toggleSearchWithSubtitle());
  const handleSearchWIthCaption = () => dispatch(toggleSearchWithCaption());
  const hoverRef = useRef(null);
  // const isHover = useHover(hoverRef);
  const handleTitle = () => {
    // copyToClipboard(`${window.location.origin}/?book=${book.id}&chapter=${chapterId}`);
    // toast(t("mainTopBar.copy.url.success"));
  };
  const navigate = useNavigate();
  const handleSearchBlur = (e) => {
    if (e.target.value && currentMode === MODE.SEARCH) handleIcon();
    else dispatch(updateMode(MODE.NULL));
  };
  const handleSetting = () => navigate("/setting");

  return (
    <div className="top-bar" ref={hoverRef}>
      <div
        className="toggle"
        onClick={() => dispatch(updateSideOpen(!currentSideOpen))}
      >
        <ion-icon name="menu-outline" />
      </div>

      {/*Title*/}
      {currentMode === MODE.NULL && (
        <div className="title" onClick={handleTitle}>
          <h1>{`${book && book.name} ${currentChapter}:`}</h1>
          {/*{*/}
          {/*    isHover*/}
          {/*        ? <h2>{t("mainTopBar.copy.text")}</h2>*/}
          {/*        : <h1>{`${book && book.name} ${chapterId}:`}</h1>*/}
          {/*}*/}
        </div>
      )}

      {/*Copy*/}
      {currentMode === MODE.SELECT && (
        <div className="title">
          <h2>{t("mainTopBar.copy.mode.select.head")}</h2>
          <h2>
            {t("mainTopBar.copy.mode.select.body", {
              count: currentSelectedWordIds.length,
            })}
          </h2>
        </div>
      )}

      {/*Search*/}
      {currentMode === MODE.SEARCH && (
        <div className="search">
          <label>
            <input
              type="text"
              placeholder=""
              onKeyDown={handleSearchKeyDown}
              value={currentSearch}
              onChange={(e) => dispatch(updateSearch(e.target.value))}
              autoFocus
              onBlur={handleSearchBlur}
            />
            <ion-icon name="search-outline" />
          </label>
        </div>
      )}
      {currentMode === MODE.SEARCH && (
        <div className="top-bar-search-check">
          {/*<div>*/}
          {/*  <input*/}
          {/*    type="checkbox"*/}
          {/*    className="form-switch"*/}
          {/*    id="search_include_subtitle"*/}
          {/*    name="search_include_subtitle"*/}
          {/*    checked={searchWithSubtitle}*/}
          {/*    onChange={handleSearchWIthSubtitle}*/}
          {/*  />*/}
          {/*  <label*/}
          {/*    className="form-check-label"*/}
          {/*    htmlFor="search_include_subtitle"*/}
          {/*  >*/}
          {/*    소제목 포함*/}
          {/*  </label>*/}
          {/*</div>*/}

          {/*<div>*/}
          {/*  <input*/}
          {/*    type="checkbox"*/}
          {/*    className="form-switch"*/}
          {/*    id="search_include_caption"*/}
          {/*    name="search_include_caption"*/}
          {/*    checked={searchWithCaption}*/}
          {/*    onChange={handleSearchWIthCaption}*/}
          {/*  />*/}
          {/*  <label*/}
          {/*    className="form-check-label"*/}
          {/*    htmlFor="search_include_caption"*/}
          {/*  >*/}
          {/*    난하주 포함*/}
          {/*  </label>*/}
          {/*</div>*/}
        </div>
      )}

      {/*userImg*/}
      <div className="user">
        {currentMode === MODE.NULL && (
          <ion-icon
            name="search-outline"
            style={currentSearch ? { color: "#f4d03f" } : {}}
            onClick={handleIcon}
          />
        )}
        {currentMode === MODE.SELECT && (
          <ion-icon name="copy-outline" onClick={handleIcon} />
        )}
        {currentMode === MODE.SEARCH && (
          <ion-icon name="search-outline" onClick={handleIcon} />
        )}
        <ion-icon
          name="settings-outline"
          class="setting"
          onClick={handleSetting}
        />
        {/*<ion-icon*/}
        {/*  name="grid-outline"*/}
        {/*  class="setting"*/}
        {/*  onClick={() => navigate("/setting")}*/}
        {/*/>*/}
        {/*<ion-icon name="grid-outline" class="user"></ion-icon>*/}
        {/*<ion-icon name="logo-google" class="user" ></ion-icon>*/}
      </div>
    </div>
  );
};

export default withTranslation("page")(MainTopBar);
