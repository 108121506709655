import React, { Suspense } from "react";
import ReactDOM from "react-dom/client";
import "./css/index.css";
import "./i18n";

import LoadingComponent from "./components/LoadingComponent";
import reportWebVitals from "./reportWebVitals";
import {
  createBrowserRouter,
  RouterProvider,
  Navigate,
} from "react-router-dom";
import { Provider } from "react-redux";
import { store } from "./store";
import LoadingPage from "./pages/LoadingPage";

import App from "./App";
import EmbedPage from "./pages/EmbedPage";
import SettingPage from "./pages/SettingPage";
const Notice = React.lazy(() => import("./components/Notice"));

const router = createBrowserRouter([
  {
    path: "/",
    element: <Navigate to="/loading" />,
  },
  {
    path: "/:bookId/:chapter",
    element: (
      <Suspense fallback={<LoadingComponent />}>
        <App />
      </Suspense>
    ),
  },
  {
    path: "/notice",
    element: <Notice />,
  },
  {
    path: "/loading",
    element: <LoadingPage />,
  },
  {
    path: "/setting",
    element: <SettingPage />,
  },
  {
    path: "/embed/:language",
    element: <EmbedPage />,
  },
]);

ReactDOM.createRoot(document.getElementById("root")).render(
  <Provider store={store}>
    <React.Suspense fallback={<LoadingComponent />}>
      <RouterProvider router={router} />
    </React.Suspense>
  </Provider>
);

reportWebVitals();
