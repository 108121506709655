import { get_caption_by_id } from "../api/load_from_server";
import SubtitleLink from "../components/WordComponent/SubtitleLink";
import WordCaption from "../components/WordComponent/WordCaption";
import SubtitleLinkComponent from "../components/WordComponentV3/SubtitleLinkComponent";
import CaptionComponent from "../components/WordComponentV3/CaptionComponent";
import WordLineComponent from "../components/WordComponentV3/WordLineComponent";
import SubtitleLineComponent from "../components/WordComponentV3/SubtitleLineComponent";
import Highlighter from "react-highlight-words";
import { loadBibleWordDataV3 } from "./database";

const REGEX_CAPTION = /<span class="caption">.+?<\/span>/g;
const REGEX_SUB_TITLE = /<span class="sub-title">.+?<\/span>/g;

export function removeCaption(wordText) {
  return wordText.replaceAll(REGEX_CAPTION, "");
}
export function removeSubtitle(wordText) {
  return wordText.replaceAll(REGEX_SUB_TITLE, "");
}

export function removeAllTag(wordText) {
  return wordText.replaceAll(REGEX_CAPTION, "").replaceAll(REGEX_SUB_TITLE, "");
}

export function filterDataShowList(data, showList) {
  if (showList.length === 1 && showList[0] === -1) return data;
  return data.filter((v) => showList.includes(Number(v.verse.split("-")[0])));
}
export function insertDataHighlight(data, highlight) {
  return data.map((v) => {
    let c = v.content;

    highlight.split(" ").forEach((v) => {
      if (!v) return;
      // https://stackoverflow.com/questions/3561493/is-there-a-regexp-escape-function-in-javascript
      const reg = new RegExp(v.replace(/[/\-\\^$*+?.()|[\]{}]/g, "\\$&"), "g");
      c = c.replaceAll(reg, `<span class="highlight">${v}</span>`);
    });
    return { ...v, content: c };
  });
}

/**
 *
 * @param data IWordData[]
 * @param jump
 * @return {*[]}
 */
export function convertDataToElemData(data, jump) {
  let rt = [];
  let verse;
  let prev;

  data.forEach((value) => {
    if (value.content_type.startsWith("subtitle")) {
      if (value.content_type === "subtitle") {
        const content = (
          <span dangerouslySetInnerHTML={{ __html: value.content }} />
        );
        if (prev && prev.content_type.startsWith("subtitle")) {
          prev.content.push(content);
        } else {
          prev = {
            id: value.id,
            content_type: "subtitle",
            content: [content],
            // content: [value.content],
            caption: [],
            verse: value.verse,
          };
          rt.push(prev);
        }
      } else if (value.content_type === "subtitle-caption") {
        const content = <sup className="subtitle-caption">{value.content}</sup>;
        const caption_content = get_caption_by_id(
          value.bible_id,
          value.chapter,
          value.caption
        ).map((v) => v.content);
        const caption = (
          <span className="subtitle-caption">
            {["〔 ", value.content, " ", ...caption_content, " 〕"]}
          </span>
        );
        if (prev && prev.content_type.startsWith("subtitle")) {
          prev.content.push(content);
          prev.caption.push(caption);
        } else {
          prev = {
            id: value.id,
            content_type: "subtitle",
            content: [content],
            caption: [caption],
            verse: value.verse,
          };
          rt.push(prev);
        }
      } else if (value.content_type === "subtitle-link") {
        const content = <SubtitleLink jump={jump} value={value} />;
        if (prev && prev.content_type.startsWith("subtitle")) {
          prev.content.push(content);
        } else {
          prev = {
            id: value.id,
            content_type: "subtitle",
            content: [content],
            caption: [],
            verse: value.verse,
          };
          rt.push(prev);
        }
      } else {
        console.error(value);
      }
    } else if (value.content_type.startsWith("word")) {
      if (value.content_type === "word") {
        const content = (
          <span dangerouslySetInnerHTML={{ __html: value.content }} />
        );
        if (
          prev &&
          prev.content_type.startsWith("word") &&
          verse === value.verse
        ) {
          prev.content.push(content);
        } else {
          prev = {
            id: value.id,
            content_type: "word",
            content: [content],
            // content: [value.content],
            caption: [],
            verse: value.verse,
          };
          verse = value.verse;
          rt.push(prev);
        }
      } else if (value.content_type === "word-caption") {
        const content = <sup className="caption">{value.content}</sup>;
        const caption = (
          <WordCaption key={value.id} jump={jump} value={value} />
        );
        if (
          prev &&
          prev.content_type.startsWith("word") &&
          verse === value.verse
        ) {
          prev.content.push(content);
          prev.caption.push(caption);
        } else {
          prev = {
            id: value.id,
            content_type: "word",
            content: [content],
            caption: [caption],
            verse: value.verse,
          };
          verse = value.verse;
          rt.push(prev);
        }
      } else {
        console.error(value);
      }
    }
  });

  return rt;
}

const convertWordContentToChildren = (word, index, handleLink, extra) => {
  const contents = [];
  const captions = [];

  switch (word.type) {
    case "subtitle":
      word.contents.forEach((content, contentIndex) => {
        if (content.type === "text") contents.push(content.text);
        else if (content.type === "link") {
          const link = {
            start: content.link[0],
            end: content.link[content.link.length - 1],
          };
          contents.push(
            <SubtitleLinkComponent
              key={`${index}-subtitle-${contentIndex}`}
              text={content.text}
              onClick={() => handleLink(link)}
            />
          );
        } else if (content.type === "captions") {
          contents.push(<sup className="caption">{content.text}</sup>);
          captions.push(
            <CaptionComponent
              key={`${index}-caption-${contentIndex}`}
              text={content.text}
              captions={content.captions}
              handleLink={handleLink}
            />
          );
        } else console.warn(content);
      });
      break;
    case "word":
      word.contents.forEach((content, contentIndex) => {
        if (content.type === "text") {
          if (extra?.searchKeywords)
            contents.push(
              <Highlighter
                key={`${index}-highlight-${contentIndex}`}
                searchWords={extra?.searchKeywords ?? []}
                autoEscape={true}
                textToHighlight={content.text}
              />
            );
          else contents.push(content.text);
        } else if (content.type === "captions") {
          contents.push(
            <sup className="caption" key={`${index}-sup-${contentIndex}`}>
              {content.text}
            </sup>
          );
          captions.push(
            <CaptionComponent
              key={`${index}-caption-${contentIndex}`}
              text={content.text}
              captions={content.captions}
              handleLink={handleLink}
            />
          );
        } else console.warn(content);
      });
      break;
    case "continue":
      contents.push("(위에 포함)");
      break;
    default:
      throw Error(
        console.log("unknown word type", word) || "unknown word type"
      );
  }
  return [...contents, ...captions];
};

const convertDataItemToElem = (word, index, handleLink, extra) => {
  switch (word.type) {
    case "subtitle":
      return (
        <SubtitleLineComponent
          ref={extra?.ref}
          key={word.id}
          children={convertWordContentToChildren(
            word,
            index,
            handleLink,
            extra
          )}
        />
      );
    case "word":
    case "continue":
      return (
        <WordLineComponent
          ref={extra?.ref}
          key={word.id}
          line={word.line}
          selected={extra?.selected}
          onClick={extra?.onClick}
          children={convertWordContentToChildren(
            word,
            index,
            handleLink,
            extra
          )}
          secondaryChildren={
            extra?.secondaryChildren
              ? convertWordContentToChildren(
                  extra.secondaryChildren,
                  index,
                  handleLink,
                  extra
                )
              : []
          }
        />
      );
    default:
      throw Error(
        console.log("unknown word type", word) || "unknown word type"
      );
  }
};
/**
 *
 * @param words
 * @param handleLink
 * @param extras
 * @return {*}
 */
export const convertDataV3ToElem = (words, handleLink, extras = []) =>
  words.map((word, index) =>
    convertDataItemToElem(word, index, handleLink, extras[index])
  );
/**
 *
 * @param addresses {{
 *           bookId: number;
 *           chapter: number;
 *           startLine: null | number;
 *           endLine: null | number;
 *         }[]}
 * @return {Promise<BibleWordDataV3[]>}
 */
export const loadWordDataV3FromAddresses = async (addresses) => {
  const words = [];
  for await (const address of addresses) {
    const datas = Array.from(
      await loadBibleWordDataV3("korean", address.bookId, address.chapter)
    );
    const startIdx =
      address.startLine === null
        ? 0
        : datas.at(
            datas.findIndex((value) => value.line === address.startLine) - 1
          ).type === "subtitle"
        ? datas.findIndex((value) => value.line === address.startLine) - 1
        : datas.findIndex((value) => value.line === address.startLine);
    const endIdx =
      address.endLine === null
        ? datas.length - 1
        : datas.findIndex((value) => value.line === address.endLine);

    datas.slice(startIdx, endIdx + 1).forEach((word) => words.push(word));
  }
  return words;
};
