import "../css/Backdrop.css";

const BackdropComponent = ({ onClick }) => (
  <div
    id="dialog-backdrop"
    className="backdrop"
    style={{
      display: "none",
    }}
    onClick={onClick}
  ></div>
);

export default BackdropComponent;
